var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-content trail",
      staticStyle: { height: "100%" },
      attrs: { id: "trail" }
    },
    [
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { size: "large", fix: "" }
        },
        [_vm._v("加载中...")]
      ),
      _c("div", { staticClass: "absolute-top" }, [
        _c(
          "div",
          { staticClass: "input-group-inline" },
          [
            _c("Input", {
              staticClass: "control-width",
              staticStyle: { width: "150px" },
              attrs: { placeholder: "请输入设备号" },
              model: {
                value: _vm.device,
                callback: function($$v) {
                  _vm.device = $$v
                },
                expression: "device"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "input-group-inline" },
          [
            _c("Input", {
              staticClass: "control-width",
              staticStyle: { width: "150px" },
              attrs: { disabled: true, placeholder: "绑定车牌号" },
              model: {
                value: _vm.carNumber,
                callback: function($$v) {
                  _vm.carNumber = $$v
                },
                expression: "carNumber"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "input-group-inline" },
          [
            _c("DatePicker", {
              staticStyle: { width: "300px" },
              attrs: {
                type: "datetimerange",
                "split-panels": "",
                placeholder: "设备运行时间",
                value: _vm.timeRange,
                format: "yyyy-MM-dd HH:mm:ss",
                editable: false
              },
              on: { "on-change": _vm.updateTimeRange }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "input-group-inline" },
          [
            _c("label", [_vm._v("定位方式(默认为GPS)：")]),
            _c(
              "Select",
              {
                staticClass: "custom-control-width",
                model: {
                  value: _vm.locateType,
                  callback: function($$v) {
                    _vm.locateType = $$v
                  },
                  expression: "locateType"
                }
              },
              [
                _c("Option", { attrs: { value: 0 } }, [_vm._v("全部")]),
                _c("Option", { attrs: { value: 1 } }, [_vm._v("GPS")]),
                _c("Option", { attrs: { value: 2 } }, [_vm._v("基站")]),
                _c("Option", { attrs: { value: 6 } }, [_vm._v("WIFI")])
              ],
              1
            )
          ],
          1
        ),
        !_vm.loading
          ? _c(
              "div",
              { staticClass: "input-group-inline" },
              [
                _c(
                  "Button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.getTrailByDevice }
                  },
                  [_vm._v("确定")]
                ),
                _c(
                  "Button",
                  {
                    staticStyle: { color: "#2d8cf0" },
                    attrs: { type: "text", loading: _vm.detailLoading },
                    on: { click: _vm.toggleTrailDetail }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.showDetailTable ? "关闭轨迹明细" : "轨迹明细"
                        ) +
                        " "
                    )
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ]),
      _c("div", { staticClass: "map-box", attrs: { id: "container" } }),
      _c("div", { staticClass: "absolute-bottom" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDetailTable,
                expression: "showDetailTable"
              }
            ]
          },
          [
            _c("Table", {
              ref: "table",
              attrs: {
                id: "table",
                "highlight-row": "",
                stripe: "",
                border: "",
                size: "small",
                height: 200,
                columns: _vm.columns,
                loading: _vm.loading,
                data: _vm.tableData
              }
            }),
            _c(
              "div",
              { staticStyle: { padding: "5px", overflow: "hidden" } },
              [
                _c(
                  "Button",
                  {
                    staticStyle: { "margin-right": "5px" },
                    attrs: { size: "small" },
                    on: { click: _vm.hideTable }
                  },
                  [_vm._v("关闭列表")]
                ),
                _c(
                  "Button",
                  { attrs: { size: "small" }, on: { click: _vm.exportExcel } },
                  [_vm._v("导出列表")]
                ),
                _c(
                  "div",
                  { staticStyle: { float: "right" } },
                  [
                    _c("Page", {
                      attrs: {
                        total: _vm.totalItems,
                        current: _vm.currentPage,
                        "show-total": "",
                        "page-size": _vm.pageSize
                      },
                      on: { "on-change": _vm.changePage }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "map-navigator-control flex flex-middle" },
        [
          _c(
            "div",
            { staticClass: "speed-box" },
            [
              _c("Icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.navigatorState === "stop" ||
                      _vm.navigatorState === "pause",
                    expression:
                      "navigatorState === 'stop' || navigatorState === 'pause'"
                  }
                ],
                attrs: { type: "md-play", color: "#2d8cf0", size: "20" },
                on: { click: _vm.playNavigator }
              }),
              _c("Icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.navigatorState === "play",
                    expression: "navigatorState === 'play'"
                  }
                ],
                attrs: { type: "md-pause", color: "#2d8cf0", size: "20" },
                on: { click: _vm.stopNavigator }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { width: "200px", margin: "0 10px" } },
            [
              _c("Slider", {
                attrs: { "tip-format": _vm.navigatorProgressTip },
                on: { "on-change": _vm.changeNavigatorProgress },
                model: {
                  value: _vm.navigatorProgress,
                  callback: function($$v) {
                    _vm.navigatorProgress = $$v
                  },
                  expression: "navigatorProgress"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-right": "10px" } },
            [
              _c(
                "Button",
                { attrs: { size: "small", shape: "circle", disabled: "" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(Math.round(_vm.playDistance / 1000)) +
                      "km/" +
                      _vm._s(Math.round(_vm.totalDistance / 1000)) +
                      "km "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "RadioGroup",
            {
              attrs: { size: "small", type: "button" },
              model: {
                value: _vm.speedLevel,
                callback: function($$v) {
                  _vm.speedLevel = $$v
                },
                expression: "speedLevel"
              }
            },
            [
              _c("Radio", { attrs: { label: "慢速" } }, [_vm._v("慢速")]),
              _c("Radio", { attrs: { label: "正常" } }, [_vm._v("正常")]),
              _c("Radio", { attrs: { label: "快速" } }, [_vm._v("快速")]),
              _c("Radio", { attrs: { label: "极快" } }, [_vm._v("极快")])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }